<template>
  <div class="index my-orders">
    <van-nav-bar title="我的订单" left-arrow @click-left="onBack" />
    <van-tabs
      v-model:active="active"
      sticky
      class="tab-nav-bar"
      @change="onChangeOrders"
    >
      <van-tab title="全部"></van-tab>
      <van-tab title="待付款"></van-tab>
      <van-tab title="待消费"></van-tab>
      <van-tab title="售后"></van-tab>
    </van-tabs>
    <div v-if="orders.length > 0">
      <div class="coupon" v-for="order in orders" :key="order.id">
        <div class="rest-name" @click="onRestaurant(order.restaurantId)">
          <van-icon name="shop-o" />
          {{ order.restaurantName }} {{ order.cookingStyleName }} ¥{{
            order.restaurantPricerPerPerson
          }}/人
          <van-icon name="arrow" />
          <span class="coupon-status">{{getOrderStatus(order)}}</span>
        </div>
        <van-row>
          <van-col span="8">
            <van-image :src="order.productImageUrl" width="95%" height="92px" @click="onDish(order.productId)"/>
          </van-col>
          <van-col span="15" offset="1">
            <div class="coupon-detail">
              <div class="coupon-name" @click="onDish(order.productId)">{{ order.productName }}</div>
              <div>
                <span class="price">¥{{ order.productPrice }}</span>
                <span class="ori-price"
                  >原价:¥{{ order.productOriginPrice }}</span
                >
              </div>
              <div class="coupon-info" v-if="order.paid && !order.verified">
                <span>有效期至:</span><span class="time">{{getTime(order.deadlineTime)}}</span>
              </div>
              <div class="coupon-info" v-if="order.verified">
                <span>消费时间:</span><span class="time">{{getTime(order.verifiedTime)}}</span>
              </div>
              <div class="coupon-info">
                <span>创建时间:</span><span class="time">{{getTime(order.createTime)}}</span>
              </div>
              <div class="coupon-info" v-if="isWaitUse(order)">
                <van-button
                  round
                  size="mini"
                  type="primary"
                  @click="onOrderCard(order.orderId)"
                  >查看卷码</van-button
                >
              </div>
              <div class="coupon-info" v-if="isWaitPayment(order)">
                <van-button
                  round
                  size="mini"
                  type="primary"
                  @click="onOrder(order.orderId)"
                  >立即支付</van-button
                >
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-empty description="您还没有相关订单" v-if="orders.length == 0" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { parseTime } from "../../utils/index"
import OrderService from "../../api/order-service";
import "../../css/my-orders.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      orders: [],
      active: 0,
    });
    const router = useRouter();
    const { status } = useRoute().params;
    if (status === "unpaid") {
      state.active = 1;
    } else if (status === "paid") {
      state.active = 2;
    } else if (status === "aftersale") {
      state.active = 3;
    }
    const onBack = () => router.push("/my");
    const onRestaurant = (id) => router.push("/restaurant/" + id);
    const onDish = (id) => router.push("/dish/" + id);
    const onOrder = (id) => router.push("/order/" + id);
    const getTime = time => parseTime(new Date(time), "{y}-{m}-{d} {h}:{i}")
    // const isCanceled = order => order.status == "CACNEL"
    const isWaitPayment = order => {
      let orderStatus = order.status || "";
      if( orderStatus == "CANCEL") {
        return false;
      } else if(!order.paid) {
        return true;
      }
      return false;
    }
    const isWaitUse = order => {
      let orderStatus = order.status || "";
      if( orderStatus == "CANCEL") {
        return false;
       } else if(order.paid && !order.verified) {
        return true;
      }
      return false;
    }
    const getOrderStatus = order => {
      let orderStatus = order.status || "";
      if( orderStatus == "CANCEL") {
        return "已取消"
      } else if(orderStatus == "REFUNDING") {
        return "退款中"
      } else if(orderStatus == "REFUND") {
        return "已退款"
      } else if(!order.paid) {
        return "待付款"
      } else if(order.paid && order.verified) {
        return "已消费"
      } else if(order.paid && !order.verified) {
        return "待消费"
      }
      return "";
    }

    const onChangeOrders = () => {
      if (state.active == 0) {
        OrderService.getOrders().then((orders) => (state.orders = orders));
      } else if (state.active == 1) {
        OrderService.getUnpaidOrders().then((orders) => (state.orders = orders));
      } else if (state.active == 2) {
        OrderService.getWaitToUseOrders().then((orders) => (state.orders = orders));
      } else if (state.active == 3) {
        // state.orders = [];
        OrderService.getSaleAfterOrders().then(orders => state.orders = orders);
      }
    };
    const onOrderCard = (id) => router.push("/order/" + id + "/card");
    onMounted(() => onChangeOrders());
    return {
      ...toRefs(state),
      onBack,
      onOrder,
      onChangeOrders,
      onOrderCard,
      onRestaurant,
      onDish,
      getTime,
      isWaitPayment,
      isWaitUse,
      getOrderStatus
    };
  },
};
</script>
