import { get, post } from "../utils/http"

function addOrder(activityId) {
    const url = "/v1/fastbuy";
    let data = {
        activityId
    }
    return post({ url, data });
}

function cancelOrder(orderId) {
    const url = "/v1/order/cancel/" + orderId;
    return post({ url});
}

function getOrder(orderId) {
    const url = "/v1/order/details/" + orderId;
    return get({ url });
}

function getOrderByEcOrderId(orderId) {
    const url = "/v1/order/ec/" + orderId;
    return get({ url });
}

function getOrders() {
    const url = "/v1/order/orders";
    return get({ url });
}

function getUnpaidOrders() {
    const url = "/v1/order/unpaid-orders";
    return get({ url });
}

function getPaidOrders() {
    const url = "/v1/order/paid-orders";
    return get({ url });
}

function getWaitToUseOrders() {
    const url = "/v1/order/wait-to-use-orders";
    return get({ url });
}

function getSaleAfterOrders() {
    const url = "/v1/order/sale-after-orders";
    return get({ url });
}

function payingOrder(orderId) {
    const url = "/v1/order/paying/" + orderId;
    return post({ url});
}

export default {
    addOrder,
    cancelOrder,
    payingOrder,
    getOrder,
    getOrderByEcOrderId,
    getOrders,
    getUnpaidOrders,
    getPaidOrders,
    getWaitToUseOrders,
    getSaleAfterOrders
}